import Cookies from "js-cookie";

import {
  checkResetPasswordToken,
  confirmResetPassword,
  login,
  resetPassword,
} from "@/services/auth";
import { UserSession } from "@/types/user";

export const useLogin = () => {
  const loginUser = async (email: string, password: string) => {
    const user = await login(email, password);

    if (user) {
      Cookies.set("currentUser", JSON.stringify(user));
    }
    return user as UserSession;
  };

  const resetPasswordUser = async (email: string) => {
    const userEmail = await resetPassword(email);
    return userEmail as string;
  };

  const confirmResetPasswordUser = async (token: string, password: string) => {
    const user = await confirmResetPassword(token, password);
    if (user) {
      Cookies.set("currentUser", JSON.stringify(user));
    }
    return user as UserSession;
  };

  const checkResetPasswordTokenUser = async (token: string) => {
    const emailData = await checkResetPasswordToken(token);
    return emailData;
  };

  return {
    loginUser,
    resetPasswordUser,
    confirmResetPasswordUser,
    checkResetPasswordTokenUser,
  };
};
